import { Avatar } from '@livechat/design-system-react-components';
import { useStyles } from '../../hooks/useStyles';
import { classNames } from '../../functions/classNames';
import { useSchedulesContext } from './hooks/useSchedulesContext';
import { RemoveSchedule } from './RemoveSchedule';

const styles = {
    list: {
        width: '100%',
    },
    header: {
        borderBottom: '1px #EEEEEF solid !important',
        '& > *': {
            minWidth: 250,
            '&:first-child': {
                paddingLeft: '56px !important',
            },
        },
        '&:hover': {
            backgroundColor: 'inherit !important',
        },
    },
    item: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 100px',
        padding: '10px 18px',
        borderBottom: '1px #EEEEEF solid',
        '&:last-child': {
            borderBottom: 'none',
        },
        '&:hover': {
            backgroundColor: '#F1F6F8',
            '& $actions': {
                visibility: 'visible',
            },
        },
    },
    flexy: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        minWidth: 250,
    },
    agent: {
        gap: 20,
    },
    agentDetails: {},
    agentName: {
        fontWeight: 600,
    },
    agentEmail: {
        color: '#677179',
    },
    date: {
        color: '#677179',
    },
    actions: {
        visibility: 'hidden',
    },
};

export const List = () => {
    const {
        list,
        header,
        item,
        flexy,
        agent,
        agentDetails,
        agentName,
        agentEmail,
        date,
        actions,
    } = useStyles(styles);
    const { schedules } = useSchedulesContext();

    return (
        <div className={list}>
            <div className={classNames([item, header])}>
                <div>Name</div>
                <div>Start date</div>
                <div>End date</div>
            </div>
            {schedules.map(
                ({ id, agent: { email, avatar, name }, dateFrom, dateTo }) => (
                    <div key={id} className={item}>
                        <div className={classNames([flexy, agent])}>
                            <Avatar type="image" shape="circle" src={avatar} />
                            <div className={agentDetails}>
                                <div className={agentName}>{name}</div>
                                <div className={agentEmail}>{email}</div>
                            </div>
                        </div>
                        <div className={classNames([flexy, date])}>
                            {dateFrom.toLocaleString()}
                        </div>
                        <div className={classNames([flexy, date])}>
                            {dateTo.toLocaleString()}
                        </div>
                        <div className={classNames([flexy, actions])}>
                            <RemoveSchedule
                                id={id}
                                name={name}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                            />
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
