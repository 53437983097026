import { useMutation } from 'react-query';
import { useConfig } from './useConfig';
import { useAuth } from './useAuth';

export const useApiMutation = ({
    key,
    path,
    method,
    axiosOpts = {},
    mutationOpts = {},
}) => {
    const { apiUrl } = useConfig();
    const { access_token: token } = useAuth();

    return useMutation(
        key,
        data =>
            fetch(apiUrl + path, {
                method,
                ...axiosOpts,
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    ...(axiosOpts?.headers || {}),
                },
                body: !data?.body ? undefined : JSON.stringify(data.body),
            }).then(res => res.json()),
        mutationOpts
    );
};
