import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from '@livechat/design-system-react-components';
import { Delete } from '@livechat/design-system-icons/react/material';
import { useStyles } from '../../hooks/useStyles';
import { useSchedulesContext } from './hooks/useSchedulesContext';
import { useApiMutation } from '../../hooks/useApiMutation';
import { ReactComponent as WarningIcon } from '../../media/warning.svg';

const styles = {
    wrapper: {
        width: '100%',
        padding: 20,
        borderBottom: '1px #EEEEEF solid',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    modal: {
        textAlign: 'center',
    },
    modalIcon: {
        marginTop: 54,
        marginBottom: 16,
    },
    modalHeading: {
        marginBottom: 16,
    },
    modalContent: {
        padding: '0 54px 40px',
    },
    modalFooter: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        gap: 8,
    },
    agentDetails: {
        margin: '5px 0',
    },
};

export const RemoveSchedule = ({ id, name, dateFrom, dateTo }) => {
    const {
        wrapper,
        modal,
        modalIcon,
        modalHeading,
        modalContent,
        modalFooter,
        agentDetails,
    } = useStyles(styles);
    const [modalVisible, setModalVisible] = useState(false);
    const toggleModal = useCallback(() => setModalVisible(prev => !prev), []);
    const { scheduleRemoved } = useSchedulesContext();

    const { mutate, isLoading } = useApiMutation({
        key: 'removeSchedule',
        path: `/schedules/${id}`,
        method: 'DELETE',
        mutationOpts: {
            onSuccess: useCallback(() => {
                scheduleRemoved(id);
                toggleModal();
            }, [scheduleRemoved, id, toggleModal]),
        },
    });
    const callApi = useCallback(() => mutate(), [mutate]);
    const footerContent = useMemo(
        () => (
            <div className={modalFooter}>
                <Button
                    kind="basic"
                    onClick={toggleModal}
                    disabled={isLoading}
                    size="large"
                >
                    Cancel
                </Button>
                <Button
                    kind="destructive"
                    onClick={callApi}
                    loading={isLoading}
                    disabled={isLoading}
                    size="large"
                >
                    Delete
                </Button>
            </div>
        ),
        [modalFooter, toggleModal, isLoading, callApi]
    );

    return (
        <div className={wrapper}>
            {modalVisible && (
                <Modal
                    className={modal}
                    onClose={toggleModal}
                    footer={footerContent}
                >
                    <div className={modalIcon}>
                        <WarningIcon />
                    </div>
                    <h2 className={modalHeading}>Are you sure?</h2>
                    <div className={modalContent}>
                        <div>You are going to delete schedule:</div>
                        <div className={agentDetails}>
                            {name}, {dateFrom.toLocaleString()} -{' '}
                            {dateTo.toLocaleString()}
                        </div>
                        <div>This action cannot be undone.</div>
                    </div>
                </Modal>
            )}
            <Button
                kind="destructive"
                size="medium"
                icon={<Icon source={Delete} />}
                onClick={toggleModal}
            >
                Remove
            </Button>
        </div>
    );
};

RemoveSchedule.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
};
