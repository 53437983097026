import { Loader as DSLoader } from '@livechat/design-system-react-components';
import { useStyles } from '../hooks/useStyles';

export const Loader = props => {
    const { fullscreen } = useStyles({
        fullscreen: {
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
    });

    return (
        <div className={fullscreen}>
            <DSLoader {...props} />
        </div>
    );
};
