import { useEffect, useMemo } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { useApiQuery } from '../../hooks/useApiQuery';
import { SchedulesContext } from './SchedulesContext';
import { Loader } from '../Loader';
import { AddSchedule } from './AddSchedule';
import { List } from './List';
import { useSchedulesReducer } from './hooks/useSchedulesReducer';

const styles = {
    wrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
};

export const Schedules = () => {
    const {
        state,
        actions: { schedulesFetched, scheduleRemoved, scheduleAdded },
    } = useSchedulesReducer();
    const { wrapper } = useStyles(styles);

    const { data: agents } = useApiQuery({
        key: 'agents',
        path: '/agents',
    });

    const { data: schedules } = useApiQuery({
        key: 'schedules',
        path: '/schedules',
    });

    useEffect(() => {
        if (!schedules || !agents) {
            return;
        }

        schedulesFetched(
            schedules.reduce((acc, { id, agentId, dateFrom, dateTo }) => {
                const agent = agents.find(({ id: aid }) => aid === agentId);

                if (!agent) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        id,
                        agent: {
                            email: agent.email,
                            avatar: agent.avatar,
                            name: agent.name,
                        },
                        dateFrom: new Date(dateFrom),
                        dateTo: new Date(dateTo),
                    },
                ];
            }, [])
        );
    }, [schedules, agents, schedulesFetched]);

    const context = useMemo(
        () => ({ schedules: state, agents, scheduleRemoved, scheduleAdded }),
        [state, agents, scheduleRemoved, scheduleAdded]
    );

    if (!schedules) {
        return <Loader label="Loading schedules..." />;
    }

    return (
        <SchedulesContext.Provider value={context}>
            <div className={wrapper}>
                <AddSchedule />
                <List />
            </div>
        </SchedulesContext.Provider>
    );
};
