import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useStyles } from './hooks/useStyles';
import { ConfigProvider } from './components/ConfigProvider/ConfigProvider';
import { AuthProvider } from './components/AuthProvider/AuthProvider';
import { Schedules } from './components/Schedules/Schedules';

import '@fontsource/source-sans-pro';
import '@livechat/design-system-react-components/dist/style.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const styles = {
    '@global': {
        html: {
            width: '100%',
            height: '100%',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            boxSizing: 'border-box',
        },
        '*, *::before, *::after': {
            boxSizing: 'inherit',
        },
        body: {
            width: '100%',
            height: '100%',
            fontSize: 15,
            lineHeight: '22px',
            fontWeight: 400,
            backgroundColor: '#fff',
            color: '#424D57',
            padding: 0,
            margin: 0,
            fontFamily: '"Source Sans Pro", sans-serif',
        },
        h1: {
            fontSize: 32,
            lineHeight: '40px',
            fontWeight: 600,
            margin: 0,
        },
        h2: {
            fontSize: 24,
            lineHeight: '28px',
            fontWeight: 600,
            margin: 0,
        },
        h3: {
            fontSize: 20,
            lineHeight: '24px',
            fontWeight: 600,
            margin: 0,
        },
        h4: {
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 600,
            margin: 0,
        },
        a: {
            color: '#4384f5',
            textDecoration: 'underline',
        },
        '#root': {
            height: '100%',
        },
        '[class^=lc-]': {
            fontFamily:
                '"Source Sans Pro",-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif',
        },
    },
    app: {
        height: '100%',
    },
};

/**
 * @returns {import('react').Component}
 */
export const App = () => {
    const { app } = useStyles(styles);

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools />
            <div className={app}>
                <ConfigProvider>
                    <AuthProvider>
                        <Schedules />
                    </AuthProvider>
                </ConfigProvider>
            </div>
        </QueryClientProvider>
    );
};
