export const classNames = classes => {
    if (classes instanceof Array) {
        return classes.filter(Boolean).join(' ');
    }

    const c = Object.keys(classes).filter(
        className => classes[className] === true
    );

    return c.join(' ');
};
