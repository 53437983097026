import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import AccountsSDK from '@livechat/accounts-sdk';
import { AuthContext } from './AuthContext';
import { useConfig } from '../../hooks/useConfig';
import { Loader } from '../Loader';

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const { clientId } = useConfig();

    useEffect(() => {
        if (auth) {
            return;
        }

        (async () => {
            const redirect = new AccountsSDK({
                client_id: clientId,
                state: encodeURIComponent(window.location.search),
            }).redirect();
            try {
                const authData = await redirect.authorizeData();
                setAuth(authData);
            } catch (e) {
                redirect.authorize();
            }
        })();
    }, [auth, clientId]);

    if (!auth) {
        return <Loader label="Authorizing..." />;
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
