import { useCallback, useMemo, useState } from 'react';
import {
    Modal,
    Button,
    Icon,
    Picker,
    Avatar,
    FormField,
    Input,
} from '@livechat/design-system-react-components';
import { Add } from '@livechat/design-system-icons/react/material';
import { useStyles } from '../../hooks/useStyles';
import { useSchedulesContext } from './hooks/useSchedulesContext';
import { useApiMutation } from '../../hooks/useApiMutation';

const styles = {
    wrapper: {
        width: '100%',
        padding: 20,
        borderBottom: '1px #EEEEEF solid',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    modal: {},
    agent: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        gap: 8,
    },
    modalContent: {
        width: 440,
    },
    modalFooter: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        gap: 8,
    },
    datePickerWrapper: {
        marginTop: 40,
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '100%',
        justifyContent: 'space-between',
    },
    datePicker: {
        width: 200,
    },
};

export const AddSchedule = () => {
    const {
        wrapper,
        modal,
        modalContent,
        agent,
        modalFooter,
        datePickerWrapper,
        datePicker,
    } = useStyles(styles);
    const [modalVisible, setModalVisible] = useState(false);
    const [form, setFormState] = useState({
        agent: [],
        dateFrom: '',
        dateTo: '',
    });
    const toggleModal = useCallback(() => setModalVisible(prev => !prev), []);
    const { agents, scheduleAdded } = useSchedulesContext();

    const { mutate, isLoading } = useApiMutation({
        key: 'addSchedule',
        path: '/schedules',
        method: 'POST',
        mutationOpts: {
            onSuccess: useCallback(
                schedule => {
                    scheduleAdded(schedule, agents);
                    toggleModal();
                },
                [agents, scheduleAdded, toggleModal]
            ),
        },
    });
    const handlePick = useCallback(([selectedAgent]) => {
        setFormState(prev => ({
            ...prev,
            agent: selectedAgent,
        }));
    }, []);
    const handleStartDateChange = useCallback(({ target: { value } }) => {
        setFormState(prev => ({
            ...prev,
            dateFrom: value,
        }));
    }, []);
    const handleEndDateChange = useCallback(({ target: { value } }) => {
        setFormState(prev => ({
            ...prev,
            dateTo: value,
        }));
    }, []);

    const agentItem = useCallback(
        (avatar, name) => (
            <div className={agent}>
                <Avatar type="image" src={avatar} size="xsmall" />
                <div>{name}</div>
            </div>
        ),
        [agent]
    );

    const callApi = useCallback(
        () =>
            mutate({
                body: {
                    agentId: form.agent.key,
                    dateFrom: form.dateFrom,
                    dateTo: form.dateTo,
                },
            }),
        [mutate, form]
    );
    const formContent = useMemo(
        () =>
            agents?.length && (
                <div className={modalContent}>
                    <div>
                        <Picker
                            label="Agent"
                            options={agents.map(({ id, name, avatar }) => ({
                                key: id,
                                name,
                                customElement: {
                                    listItemBody: agentItem(avatar, name),
                                    selectedItemBody: agentItem(avatar, name),
                                },
                            }))}
                            onSelect={handlePick}
                            placeholder="Pick an agent"
                            selected={[form.agent]}
                            isRequired
                        />
                    </div>
                    <div className={datePickerWrapper}>
                        <FormField labelText="Start date">
                            <Input
                                name="dateFrom"
                                className={datePicker}
                                onChange={handleStartDateChange}
                                required
                            />
                        </FormField>
                        <FormField labelText="End date">
                            <Input
                                name="dateTo"
                                className={datePicker}
                                onChange={handleEndDateChange}
                                required
                            />
                        </FormField>
                    </div>
                </div>
            ),
        [
            agents,
            modalContent,
            handlePick,
            form.agent,
            datePickerWrapper,
            datePicker,
            handleStartDateChange,
            handleEndDateChange,
            agentItem,
        ]
    );
    const footerContent = useMemo(
        () => (
            <div className={modalFooter}>
                <Button
                    kind="basic"
                    onClick={toggleModal}
                    disabled={isLoading}
                    size="large"
                >
                    Cancel
                </Button>
                <Button
                    kind="primary"
                    onClick={callApi}
                    loading={isLoading}
                    disabled={isLoading}
                    size="large"
                >
                    Add schedule
                </Button>
            </div>
        ),
        [modalFooter, toggleModal, isLoading, callApi]
    );

    return (
        <div className={wrapper}>
            {modalVisible && (
                <Modal
                    className={modal}
                    onClose={toggleModal}
                    heading="Add schedule"
                    footer={footerContent}
                >
                    {formContent}
                </Modal>
            )}
            <Button
                kind="primary"
                size="medium"
                icon={<Icon source={Add} />}
                onClick={toggleModal}
            >
                Add schedule
            </Button>
        </div>
    );
};
