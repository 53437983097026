import axios from 'axios';
import { useQuery } from 'react-query';
import { useConfig } from './useConfig';
import { useAuth } from './useAuth';

export const useApiQuery = ({ key, path, axiosOpts = {}, queryOpts = {} }) => {
    const { apiUrl } = useConfig();
    const { access_token: token } = useAuth();

    return useQuery(
        key,
        () =>
            axios
                .get(apiUrl + path, {
                    ...axiosOpts,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                        ...(axiosOpts?.headers || {}),
                    },
                })
                .then(res => res.data),
        queryOpts
    );
};
