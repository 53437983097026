import { useReducer, useCallback } from 'react';

const ACTIONS = {
    SCHEDULES_FETCHED: 'SCHEDULES_FETCHED',
    SCHEDULE_REMOVED: 'SCHEDULE_REMOVED',
    SCHEDULE_ADDED: 'SCHEDULE_ADDED',
};

const initialState = [];

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SCHEDULES_FETCHED:
            return action.schedules;
        case ACTIONS.SCHEDULE_REMOVED:
            return state.filter(({ id }) => id !== action.id);
        case ACTIONS.SCHEDULE_ADDED: {
            const agent = action.agents.find(
                ({ id }) => id === action.schedule.agentId
            );

            if (!agent) {
                return state;
            }

            return [
                ...state,
                {
                    id: action.schedule.id,
                    agent: {
                        email: agent.email,
                        avatar: agent.avatar,
                        name: agent.name,
                    },
                    dateFrom: new Date(action.schedule.dateFrom),
                    dateTo: new Date(action.schedule.dateTo),
                },
            ];
        }
        default:
            return state;
    }
};

export const useSchedulesReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const schedulesFetched = useCallback(schedules => {
        dispatch({ type: ACTIONS.SCHEDULES_FETCHED, schedules });
    }, []);

    const scheduleRemoved = useCallback(id => {
        dispatch({ type: ACTIONS.SCHEDULE_REMOVED, id });
    }, []);

    const scheduleAdded = useCallback((schedule, agents) => {
        dispatch({ type: ACTIONS.SCHEDULE_ADDED, schedule, agents });
    }, []);

    return {
        state,
        actions: {
            schedulesFetched,
            scheduleRemoved,
            scheduleAdded,
        },
    };
};
