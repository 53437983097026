import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import axios from 'axios';
import { ConfigContext } from './ConfigContext';
import { Loader } from '../Loader';

let configUrl = new URLSearchParams(window.location.search.substring(1)).get(
    'config'
);

if (!configUrl) {
    configUrl = new URLSearchParams(
        decodeURIComponent(
            new URLSearchParams(window.location.hash.substring(1)).get('state')
        )
    ).get('config');
}

export const ConfigProvider = ({ children }) => {
    const { data: config } = useQuery('config', () =>
        axios.get(configUrl).then(({ data }) => data)
    );

    if (!config) {
        return <Loader label="Fetching config..." />;
    }
    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

ConfigProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
